import {
  faDownload,
  faUpload,
  faBars,
  faGripHorizontal,
  faPen,
  faSquarePlus,
  faSquareXmark,
  faTrash,
  faPlay,
  faRightFromBracket,
  faSort,
  faSortUp,
  faSortDown,
  faFileLines
} from "@fortawesome/free-solid-svg-icons"



export default {
  create: faSquarePlus,
  delete: faTrash,
  edit: faPen,
  upload: faUpload,
  download: faDownload,
  play: faPlay,
  list: faBars,
  listFile: faFileLines,
  drag: faGripHorizontal,
  clear: faSquareXmark,
  logout: faRightFromBracket,
  noSort: faSort,
  sortAsc: faSortUp,
  sortDesc: faSortDown
}