import { IEnvironment } from "./environment.base";

declare var API_BASE : string;

let baseurl = API_BASE || "http://localhost:8080/"
while (baseurl.endsWith('/')) baseurl = baseurl.slice(0, -1);

const environment: IEnvironment = {
  baseurl
}


export default environment;