import './style/index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './components/app';
import { ICollection, IEntry } from '@kw/nv-common/dist/interfaces';

export default App;


export function iequals(a?: string, b?:string) {
    return a?.toLowerCase() === b?.toLowerCase();
}

export function iincludes(a?: string, b?: string) {
    if (!b) return true;
    if (!a) return false;

    return a.toLowerCase().includes(b.toLowerCase());
}

export function matchesFilter (item: ICollection | IEntry, filter?: string, allCollections?: ICollection[]) {
    if (!filter) return true;

    if (filter.startsWith("id:"))
      return item.id === +(filter.substring(3).trim());

    if (filter.startsWith("s:") && allCollections) {
        let 
            cfilter = filter.slice(2).trim(),
            cc = allCollections.find(x => iincludes(x.title, cfilter));

        return cfilter.length && !!cc && !!cc.entries.find(x => x.id === item.id);
    }

    return iincludes(item.title, filter)
        || item.tags?.some(x => iincludes(x, filter))
        || iincludes((item as IEntry).composer, filter)
        || iincludes((item as IEntry).arranger, filter)
}

