import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import icons from "../../icons"
import { getAuth, setAuth } from "../../localsettings"
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome'
import { useContext, useEffect } from 'preact/hooks';

import { ProfileContext } from '../app';

const Header = () => {
  const context = useContext(ProfileContext);

  useEffect(() => {
    document.title = context?.profile?.profiletitle  ? `Notenverwaltung - ${context?.profile?.profiletitle}`: "Notenverwaltung";
    document.documentElement.style.setProperty('--headercolor', context?.profile?.headercolor || "#cccccc");
    document.documentElement.style.setProperty('--headertextcolor', context?.profile?.headertextcolor || "#fff");
  })

  return (
    <header className={style.header}>
      <h1>Notenverwaltung {context?.profile?.profiletitle ? ` - ${context?.profile?.profiletitle}` : ""}</h1>
      <nav>
        <Link activeClassName={style.active} href="/entries">Musikstücke</Link>
        <Link activeClassName={style.active} href="/collections">Sammlungen</Link>
        <Link activeClassName={style.active} href="/settings">Einstellungen</Link>
        {!!getAuth() && (<span className={`action ${style.logouticon}`} onClick={() => {
          setAuth();
          window.location.reload();
        }}><I icon={icons.logout} /></span>)}
      </nav>
    </header>
  )
};

export default Header;
