export function getEntryFilter() {
  return localStorage.getItem("settings:lastentryfilter") || undefined;
}

export function setEntryFilter(filter?: string) {
  if (!filter)
    localStorage.removeItem("settings:lastentryfilter")
  else
    localStorage.setItem("settings:lastentryfilter", filter);
}

export function getCollectionFilter() {
  return localStorage.getItem("settings:lastcollectionfilter") || undefined;
}

export function setCollectionFilter(filter?: string) {
  if (!filter)
    localStorage.removeItem("settings:lastcollectionfilter")
  else
    localStorage.setItem("settings:lastcollectionfilter", filter);
}

export function getLastRoute() {
  return localStorage.getItem("settings:lastroute");
}

export function setLastRoute(route?: string) {
  if (!route)
    localStorage.removeItem("settings:lastroute");
  else
    localStorage.setItem("settings:lastroute", route);
}

export function getSettingsTab() {
  return localStorage.getItem("settings:settingstab");
}

export function setSettingsTab(tab?: string) {
  if (!tab)
    localStorage.removeItem("settings:settingstab");
  else
    localStorage.setItem("settings:settingstab", tab);
}

export function getAuth( ) {
  return localStorage.getItem("settings:auth");
}

export function setAuth(username?: string, password?: string) {
  if (!(username && password))
    localStorage.removeItem("settings:auth");
  else
    localStorage.setItem("settings:auth", btoa(`${username}:${password}`));
}

