import { createContext, h, Fragment, createRef } from 'preact';
import { route, Route, Router } from 'preact-router';

import Header from './header';

import Start from '../routes/start';
import Collections from '../routes/collections';
import Entries from '../routes/entries';
import Settings from '../routes/settings';
import { IUser } from '@kw/nv-common/dist/interfaces';
import { useEffect, useState } from 'preact/hooks';
import { getProfile } from '../api'

import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Alert from "react-bootstrap/Alert"
import { getLastRoute, setAuth } from '../localsettings';


export const ProfileContext = createContext<{
  loggedin: boolean | null,
  profile: IUser | null,
  reloadProfile: () => void,
  updateProfile: (profile: IUser) => void
}>({ loggedin: false, profile: null, reloadProfile: () => { }, updateProfile: (p: IUser) => {} });

const App = () => {

  const
    [profile, setProfile] = useState(null as IUser | null),
    [loggedin, setLoggedin] = useState(null as boolean | null),
    [username, setUsername] = useState(""),
    [alert, setAlert] = useState(""),
    passwordInput = createRef<HTMLInputElement>();

  const updateProfile = (p: IUser) => {
    if (!profile) return;
    if (p.headercolor !== undefined) profile.headercolor = p.headercolor
    if (p.headertextcolor !== undefined) profile.headertextcolor = p.headertextcolor
    if (p.profiletitle !== undefined) profile.profiletitle = p.profiletitle
    if (p.openinbrowser !== undefined) profile.openinbrowser = p.openinbrowser
    if (p.confirmdelete !== undefined) profile.confirmdelete= p.confirmdelete

    setProfile(profile);
  }

  const reloadProfile = async () => {
    try {
      let p = await getProfile();
      if (p) {
        setProfile(p);
        setLoggedin(true);
        route(getLastRoute() || "/entries", true);
      } else {
        setProfile(null);
        setLoggedin(false);
      }
    } catch {
      setProfile(null);
      setLoggedin(false);
    }
  }

  const updateUsername = (event: InputEvent) => {
    let
      target = event.target as HTMLInputElement;
    setUsername(target.value);
  }

  const dismissAlert = () => {
    setAlert("");
  }

  const login = async () => {
    setAuth(username, passwordInput.current?.value);
    let p = await getProfile();
    if (p) {
      setLoggedin(true);
      setProfile(p);
      setUsername("");
      route(getLastRoute() || "/entries", true);
    } else {
      setAlert("Falscher Benutzername oder Passwort");
    }

  }

  useEffect(() => {
    reloadProfile();
  }, [])



  return (
    <div id="app">
      <>
        <ProfileContext.Provider value={{ profile, loggedin, reloadProfile, updateProfile }}>
          <Header />
          <Router >
            <Route path="/" component={Start} default={true} />
            <Route path="/entries/" component={Entries} />
            <Route path="/collections/" component={Collections} />
            <Route path="/settings/" component={Settings} />
          </Router>
        </ProfileContext.Provider>
        {loggedin === false && <Modal
          show={true}
          centered
          size="sm">
          <Modal.Header>
            <Modal.Title>Anmelden</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate>
              <FloatingLabel label="Benutzername">
                <Form.Control size="sm" type="string" placeholder="Benutzername" value={username} onBlur={updateUsername} />
              </FloatingLabel>
              <FloatingLabel label="Passwort">
                <Form.Control ref={passwordInput} size="sm" type="password" placeholder="Password" value="" />
              </FloatingLabel>
            </Form>
            {<Alert show={alert?.length} variant="danger" dismissible onClose={dismissAlert}>
              <p>{alert}</p>
            </Alert>}
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="primary" onClick={login}>Anmelden</Button>
          </Modal.Footer>
        </Modal>}
      </>
    </div>
  );
}

export default App;
