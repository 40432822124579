import env from "./environments/environment"
import {
  FullScanHandling,
  ICollection,
  IEntry,
  IScore,
  IUpload,
  IUser,
  IDictNumber,
  IDictStringArray
} from "@kw/nv-common/dist/interfaces"
import { getAuth } from "./localsettings"
import { route } from "preact-router";

export interface IScoreWithCount extends IScore {
  count: number
}

async function exec(path: string, options?: RequestInit) {
  try {
    if (getAuth()) {
      if (!options) options = {};
      if (!options.headers) options.headers = {};
      options.headers = { ...options.headers, "Authorization": `Basic ${getAuth()}` };
    }

    while (path.startsWith("/")) path = path.slice(1);
    let
      url = `${env.baseurl}/api/${path}`,
      response = await fetch(url, options),
      status = response.status,
      body: unknown;

    if (status === 401) {
      route("/", true);
      return {
        success: false,
        status,
      }
    }

    let contenttype = response.headers.get("content-type");
    if (contenttype?.startsWith("application/json")) {
      body = await response.json();
    } else if (contenttype?.startsWith("text/")) {
      body = await response.text();
    } else if (contenttype?.startsWith("application/pdf")) {
      body = await response.blob();
    }

    return {
      success: response.ok,
      status,
      contenttype,
      body
    };
  }
  catch (e) {
    return {
      success: false,
      status: 0,
      body: (e as Error).message || e
    }
  }
}

export function downloadFile(filename: string, content: string | Blob, type: string, openinbrowser: boolean) {
  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob([content], { type }));
  if (openinbrowser) {
    downloadLink.target = "_blank";
  } else {
    downloadLink.download = filename;
  }
  document.body.appendChild(downloadLink);
  downloadLink.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(downloadLink.href);
    downloadLink.remove();
  }, 500);
}

export async function getProfile() {
  let profile = await exec("/profile");
  if (profile.success) return profile.body as IUser;
}

export async function setProfile(profile: IUser) {
  let p = await exec("/profile", { 
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(profile)
  });
  return p.success;
}

export async function getEntries() {
  let entries = await exec("/entries");
  if (entries.success) return entries.body as IEntry[];
}

export async function getEntry(entryid: number) {
  let entries = await exec(`/entries/${entryid}`);
  if (entries.success) return entries.body as IEntry;
}

export async function updateEntry(entry: IEntry) {
  let col = await exec(`/entries/${entry.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(entry)
  });
  if (col.success) return col.body as IEntry;

}

export async function deleteEntry(entryid: number) {
  let del = await exec(`/entries/${entryid}`, {
    method: "DELETE"
  });
  if (del.success) return true;
}

export async function createEntry(entry: IEntry) {
  let col = await exec(`/entries`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(entry)
  });

  if (col.success) return col.body as IEntry;
}

export async function createUpload(entryid: number, pdf: File, index: File, fullscan: FullScanHandling = "join") {
  let formdata = new FormData();
  formdata.append("pdf", pdf);
  formdata.append("index", index);
  formdata.append("fullscan", fullscan)
  let upload = await exec(`/entries/${entryid}/pdf`, {
    method: "POST",
    body: formdata
  });

  if (upload.success) return upload.body as IUpload;
}

export async function checkUploadStatus(uploadid: string) {
  let upload = await exec(`/uploads/${uploadid}`);
  if (upload.success) return upload.body as IUpload;
}

export async function getPDFTemplate() {
  let template = await exec("/entries/pdftemplate");
  if (template.success) return template.body as string;
}

export async function getEntryPDF(entryid: number, scores: { score: string, count: number }[]) {
  let
    query = scores
      .filter(x => !!x.score && x.count > 0)
      .map(x => `${x.score}=${x.count}`)
      .join("&"),
    pdf = await exec(`/entries/${entryid}/pdf?${query}`);
  if (pdf.success) return pdf.body as Blob;
  return pdf.body as string;
}

export async function getCollections() {
  let entries = await exec("/collections");
  if (entries.success) return entries.body as ICollection[];
}

export async function updateCollection(collection: ICollection) {
  let col = await exec(`/collections/${collection.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(collection)
  });

  if (col.success) return col.body as ICollection;

}

export async function createCollection(collection: ICollection) {
  let col = await exec(`/collections`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(collection)
  });

  if (col.success) return col.body as ICollection;
}

export async function deleteCollection(collectionid: number) {
  let del = await exec(`/collections/${collectionid}`, {
    method: "DELETE"
  });
  if (del.success) return true;
}

export async function getCollectionPDF(collectionid: number, scores: { score: string, count: number }[]) {
  let
    query = scores
      .filter(x => !!x.score && x.count > 0)
      .map(x => `${x.score}=${x.count}`)
      .join("&"),
    pdf = await exec(`/collections/${collectionid}/pdf?${query}`);
  if (pdf.success) return pdf.body as Blob;
  return pdf.body as string;
}

export async function getCollectionIndex(collectionid: number, small: boolean = false) {
  let pdf = await exec(`/collections/${collectionid}/index?small=${small}`);
  if (pdf.success) return pdf.body as Blob;
  return pdf.body as string;
}

export async function addToCollection(collectionid: number, entries: number[]) {
  let res = await exec(`/collections/${collectionid}/entries/add`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ entries })
  });

  if (res.success) return true;
}

export async function removeFromCollection(collectionid: number, entryid: number) {
  let res = await exec(`/collections/${collectionid}/entries/${entryid}`, {
    method: "DELETE"
  });

  if (res.success) return true;
}

export async function setCollectionEntries(collectionid: number, entries: number[]) {
  let res = await exec(`/collections/${collectionid}/entries`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ entries })
  });

  if (res.success) return true;

}

let scoreCache: {
  current?: IScore[],
  default?: IScore[]
} = {}
export async function getScores(set: "current" | "default", refresh = false) {
  if (scoreCache[set] && !refresh) return clone(scoreCache[set]);
  let sc = await exec(`/definitions/${set}/scores`);
  if (sc.success) {
    scoreCache[set] = sc.body as IScore[];
    return clone(scoreCache[set]);
  }
}

let lineupCache: {
  current?: IDictNumber,
  default?: IDictNumber
} = {}
export async function getLineup(set: "current" | "default", refresh = false) {
  if (lineupCache[set] && !refresh) return clone(lineupCache[set]);
  let sc = await exec(`/definitions/${set}/lineup`);
  if (sc.success) {
    lineupCache[set] = sc.body as IDictNumber;
    return clone(lineupCache[set]);
  }
}

let alternativesCache: {
  current?: IDictStringArray,
  default?: IDictStringArray
} = {}
export async function getAlternatives(set: "current" | "default", refresh = false) {
  if (alternativesCache[set] && !refresh) return clone(alternativesCache[set]);
  let sc = await exec(`/definitions/${set}/alternatives`);
  if (sc.success) {
    alternativesCache[set] = sc.body as IDictStringArray;

    return clone(alternativesCache[set]);
  }
}

export async function setLineup(lineup: IDictNumber) {
  let sc = await exec(`/definitions/current/lineup`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(lineup)
  });

  if (sc.success) {
    lineupCache.current = clone(lineup);
    return true;
  }
}

export async function setScores(scores: IScore[]) {
  let sc = await exec(`/definitions/current/scores`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(scores)
  });

  if (sc.success) {
    scoreCache.current = clone(scores);
    return true;
  }
}

export async function setAlternatives(alternatives: IDictStringArray) {
  let sc = await exec(`/definitions/current/alternatives`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(alternatives)
  });

  if (sc.success) {
    alternativesCache.current = clone(alternatives);
    return true;
  }
}


function clone<T>(val: T) {
  return JSON.parse(JSON.stringify(val)) as T;
}